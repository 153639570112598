import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { SsApiService } from '../api/ss-api.service';
import { CLAIMED_WELCOME_BONUSES_GROUP } from './data/user-group.data';

@Injectable({
  providedIn: 'root',
})
export class UserGroupsService {
  private _ssApi = inject(SsApiService);
  private _user = inject(UserService);


  /**
   * Add user to provided group
   * @param group
   */
  public addToGroup(group: any): Observable<any> {
    return this._ssApi.postPlayerGroups({
      groups: {
        add: [group],
      },
    });
  }

  /**
   * Remove user from provided group
   * @param group
   */
  public removeFromGroup(group: any): Observable<any> {
    return this._ssApi.postPlayerGroups({
      groups: {
        remove: [group],
      },
    });
  }

  /**
   * Is exist group in user`s groups
   * @param group
   */
  public isExistGroup(groupId: number | string): boolean {
    if (!this._user.auth || !this._user.info.statuses.length) {
      return null;
    }
    return Boolean(this._user.info.statuses.find(status => status.id === groupId));
  }

  /**
   * Check already added user group
   * @param status
   * @param statuses
   */
  public alreadyExistUserGroup(status: any, statuses: any[]): boolean {
    return statuses && statuses.some(item => item.id.includes(status));
  }

  public isAllWelcomeUsed(): boolean {
    return this.isExistGroup(CLAIMED_WELCOME_BONUSES_GROUP);
  }
}
